export const moduleList = [
  //year 2
  {
    year: '2020-2021',
    title: 'Introduction to Artificial Intelligence',
    level: 5,
    description: 'Planning Domain Definition Language (PDDL)',
    link:
      'https://www.kcl.ac.uk/study/courses-data/modules/4/Introduction-To-Artificial-Intelligence-4ccs1iai',
  },
  {
    year: '2020-2021',
    title: 'Practical Experiences of Programming',
    level: 5,
    description: 'C++ and Scala',
    link:
      'https://www.kcl.ac.uk/abroad/module-options/module?id=8c450242-d4e4-4853-8ad7-d1ee1ee57f0c',
  },
  {
    year: '2020-2021',
    title: 'Robotics Group Project',
    level: 5,
    description: 'Python, Ros, and Gazebo',
    link: 'https://www.kcl.ac.uk/study/courses-data/modules/5/Robotics-Group-Project-5ccs2rgp',
  },
  {
    year: '2020-2021',
    title: 'Internet Systems',
    level: 5,
    description: 'Wireshark',
    link: 'https://www.kcl.ac.uk/study/courses-data/modules/6/Internet-Systems-6ccs3ins1',
  },
  {
    year: '2020-2021',
    title: 'Operating Systems & Concurrency',
    level: 5,
    description: 'Null',
    link:
      'https://www.kcl.ac.uk/study/courses-data/modules/5/Operating-Systems-And-Concurrency-5ccs2osc1',
  },
  {
    year: '2020-2021',
    title: 'Foundations of Computing 2',
    level: 5,
    description: 'Null',
    link: 'https://www.kcl.ac.uk/study/courses-data/modules/5/Foundations-Of-Computing-2-5ccs2fc22',
  },
  {
    year: '2020-2021',
    title: 'Programming Language Design Paradigms',
    level: 5,
    description: 'Null',
    link:
      'https://www.kcl.ac.uk/study/courses-data/modules/5/Programming-Language-Design-Paradigms-5ccs2pld1',
  },

  //year 1

  {
    year: '2019-2020',
    title: 'Introduction to Software engineering',
    level: 4,
    description: 'Null',
    link:
      'https://www.kcl.ac.uk/abroad/module-options/module?id=c85872de-ec7a-4766-93a4-4c4a10543234',
  },
  {
    year: '2019-2020',
    title: 'Programming Practice & Applications ',
    level: 4,
    description: 'Java',
    link:
      'https://www.kcl.ac.uk/abroad/module-options/module?id=dd960b11-d771-4eb6-9a95-9b8e2be60121',
  },
  {
    year: '2019-2020',
    title: 'Data Structures',
    level: 4,
    description: 'Java',
    link: 'https://www.kcl.ac.uk/study/courses-data/modules/4/Data-Structures-4ccs1dst1',
  },
  {
    year: '2019-2020',
    title: 'Database Systems',
    level: 4,
    description: 'MySql',
    link:
      'https://www.kcl.ac.uk/abroad/module-options/module?id=461f0f0c-035d-422c-82a7-a155030ddeb4',
  },
  {
    year: '2019-2020',
    title: 'Computer Systems',
    level: 4,
    description: 'Null',
    link: 'https://www.kcl.ac.uk/study/courses-data/modules/4/Computer-Systems-4ccs1cs11',
  },
  {
    year: '2019-2020',
    title: 'Elementary Logic with Application',
    level: 4,
    description: 'Null',
    link:
      'https://www.kcl.ac.uk/study/courses-data/modules/4/Elementary-Logic-With-Applications-4ccs1ela',
  },
];
